import React from "react";
import { useState } from "react";
import { Col, Row, Divider } from "antd";
import "./Footer.css";
import { Input } from "antd";
import emailjs from "emailjs-com";

function Footer() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const YOUR_EMAILJS_USER_ID = "2zSRHG5NRTGk21bfR";

    const templateParams = {
      subject: `There is an email from ${email}`,
      message: `This is my Email: ${email}. and my message is: ${message}`,
      to_email: "satotroy.ji@gmail.com",
    };

    emailjs
      .send(
        "service_r4vwt5b",
        "template_jcs5zub",
        templateParams,
        YOUR_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          alert("Email sent! We will contact you soon!");
        },
        (error) => {
          alert("Email failed to send! Please contact us directly!");
        }
      );

    setMessage("");
    setEmail("");
  };

  return (
    <footer className="footerSetting">
      <Row justify="space-around">
        <Col xs={0} md={2}></Col>
        <Col xs={0} md={4}>
          <div style={{ lineHeight: 2, fontWeight: "bold" }}>
            <h1>Find us</h1>
            <Divider className="whiteDivider"></Divider>
            <p>
              NCE Corporation (S) Pte Ltd <br />
              62 Ubi Road 1, #04-22, <br />
              Oxley BizHub 2, <br />
              Singapore 408734 <br /> Tel: 62910127 <br />{" "}
              admin@ncecorporation.sg
            </p>
          </div>
        </Col>
        <Col xs={0} md={4} style={{ lineHeight: 2, fontWeight: "bold" }}>
          <h1>Quick Link</h1>
          <Divider className="whiteDivider" />
          <a className="hyLink" href="/">
            Home
          </a>
          <br />
          <a className="hyLink" href="/about">
            About
          </a>
          <br />
          <a className="hyLink" href="/services">
            Services
          </a>
          <br />
          <a className="hyLink" href="project">
            Project
          </a>
          <br />
          <a className="hyLink" href="/contact">
            Contract us
          </a>
          <br />
          <br />
        </Col>

        <Col xs={4} md={0}></Col>
        <Col xs={16} md={4} style={{ lineHeight: 2, fontWeight: "bold" }}>
          <h1>Email us</h1>
          <Divider className="whiteDivider"></Divider>
          <div>
            <form onSubmit={sendEmail}>
              <Input
                type="email"
                name="user_email"
                placeholder="Your Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input.TextArea
                placeholder="Your Message"
                required
                value={message}
                style={{ marginTop: "20px" }}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Input type="submit" value="Send" style={{ marginTop: "20px" }} />
            </form>
          </div>
        </Col>
        <Col xs={4} md={0}></Col>
        <Col xs={0} md={2}></Col>
      </Row>
      <Divider></Divider>
      <p style={{ textAlign: "center", fontWeight: "bold", padding: "30px" }}>
        © 2024 All Rights Reserved. Design by NCE Corporation (S) Pte. Ltd.
      </p>
    </footer>
  );
}

export default Footer;
