import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeIndex from "./main/homeindex";
import ProjectPage from "./FrontIndex/Project.js";
import ServicePage from "./FrontIndex/Service.js";
import ContactPage from "./FrontIndex/Contact.js";
import AboutPage from "./FrontIndex/AboutCompany.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="project" element={<ProjectPage />} />
            <Route path="/" element={<HomeIndex />} />
            <Route path="services" element={<ServicePage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="about" element={<AboutPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
