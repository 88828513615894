import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import map from "../Static/map.png";
import { Button, Form, Input, Divider } from "antd";
import emailjs from "emailjs-com";

const onFinishFailed = (errorInfo) => {
  alert("Sorry there was an error. Please try again.");
};

const lineStyle = {
  width: "1cm",
  height: "6px",
  backgroundColor: "rgb(0, 144,207",
  margin: "20px 0 20px 0",
};

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const onFinish = () => {
    const templateParams = {
      subject: `There is an email from ${email}`,
      message: `Hello, My name is: ${name}, and my Tel is: ${phone}. \n This is my Email: ${email}. and my message is: ${message}`,
      to_email: "satotroy.ji@gmail.com",
    };

    emailjs
      .send(
        "service_r4vwt5b",
        "template_jcs5zub",
        templateParams,
        "2zSRHG5NRTGk21bfR"
      )
      .then(
        (result) => {
          alert("Email sent! We will contact you soon!");
        },
        (error) => {
          console.log(error.text);
        }
      );

    setEmail("");
    setMessage("");
    setName("");
    setPhone("");
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(0, 144,207)",
          padding: isMobile ? "2px" : "5px",
          textAlign: "center",
          margin: "20px",
          color: "white",
        }}
      >
        <span
          style={{ fontSize: isMobile ? "24px" : "48px", fontWeight: "bold" }}
        >
          CONTACT US
        </span>
      </div>
      <Row style={{ margin: "20px 0 20px 0" }}>
        <Col xs={2} md={3}></Col>
        <Col xs={20} md={7}>
          <div style={{ padding: isMobile ? "0px" : "20px" }}>
            <div
              style={{
                fontSize: isMobile ? "24px" : "32px",
                fontWeight: "bold",
              }}
            >
              CONTACT INFORMATION
            </div>
            <div style={lineStyle} />
            <p
              style={{
                lineHeight: isMobile ? 1.5 : 2,
                fontSize: isMobile ? "12px" : "18px",
              }}
            >
              NCE Corporation (S) Pte Ltd <br />
              62 Ubi Road 1, #04-22, <br />
              Oxley BizHub 2, <br />
              Singapore 408734 <br /> Tel: 62910127 <br />{" "}
              admin@ncecorporation.sg
              <br />
              <div>
                Opening Hours: <br />
                -: Mon - Fri: 9am - 6pm <br />
                -: Sat: 9am - 1pm <br />
              </div>
            </p>
          </div>
        </Col>
        <Col xs={0} md={11} style={{ alignContent: "center" }}>
          <img src={map} alt="map" style={{ width: "100%" }} />
        </Col>
        <Col xs={2} md={3}></Col>
      </Row>

      <Row>
        <Col xs={2} md={0}></Col>
        <Col xs={20} md={0}>
          <img src={map} alt="map" style={{ width: "100%" }} />
        </Col>
        <Col xs={2} md={0}></Col>
      </Row>

      <Divider />
      <Row>
        <Col xs={1} md={3}></Col>
        <Col xs={22} md={18}>
          <div style={{ padding: isMobile ? "0px" : "20px" }}>
            <div
              style={{
                fontSize: isMobile ? "24px" : "32px",
                fontWeight: "bold",
              }}
            >
              CONTACT US/FEEDBACK
            </div>
            <div style={lineStyle} />
            <div>
              <Form
                name="basic"
                size="large"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email address!",
                    },
                  ]}
                >
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <Input.TextArea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: isMobile ? 9 : 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={1} md={3}></Col>
      </Row>
    </div>
  );
};

export default Contact;
