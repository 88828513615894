import React, { useState, useEffect } from "react";
import CarouselPage from "./CarouselPage.js";
import Business from "./business.js";
import About from "./about.js";
import { Divider } from "antd";

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <Divider /> : <></>}
      <CarouselPage />
      <Business />
      <About />
    </div>
  );
};

export default App;
