import React, { useState, useEffect } from "react";
import { Card, Col, Row, Tabs, Image, Divider } from "antd";
import { Collapse } from "antd";

import A10 from "../Static/Services/A&A/5A Ridley Park/0.png";
import A11 from "../Static/Services/A&A/5A Ridley Park/1.png";
import A12 from "../Static/Services/A&A/5A Ridley Park/2.png";
import A13 from "../Static/Services/A&A/5A Ridley Park/3.png";
import A14 from "../Static/Services/A&A/5A Ridley Park/4.png";
import A15 from "../Static/Services/A&A/5A Ridley Park/5.png";
import A16 from "../Static/Services/A&A/5A Ridley Park/6.png";
import A20 from "../Static/Services/A&A/Anglo-Chinies School/0.jpg";
import A21 from "../Static/Services/A&A/Anglo-Chinies School/1.png";
import A22 from "../Static/Services/A&A/Anglo-Chinies School/2.png";
import A23 from "../Static/Services/A&A/Anglo-Chinies School/3.png";
import A24 from "../Static/Services/A&A/Anglo-Chinies School/4.png";
import A25 from "../Static/Services/A&A/Anglo-Chinies School/5.png";
import A26 from "../Static/Services/A&A/Anglo-Chinies School/6.png";
import A27 from "../Static/Services/A&A/Anglo-Chinies School/7.png";
import A28 from "../Static/Services/A&A/Anglo-Chinies School/8.png";
import A30 from "../Static/Services/A&A/Singapore Management University/0.jpg";
import A31 from "../Static/Services/A&A/Singapore Management University/1.jpg";
import A32 from "../Static/Services/A&A/Singapore Management University/2.jpg";
import A33 from "../Static/Services/A&A/Singapore Management University/3.jpg";
import A34 from "../Static/Services/A&A/Singapore Management University/4.jpg";
import A35 from "../Static/Services/A&A/Singapore Management University/5.jpg";
import B10 from "../Static/Services/Civil Construction Project/Braddle MRT/0.jpg";
import B11 from "../Static/Services/Civil Construction Project/Braddle MRT/1.jpeg";
import B12 from "../Static/Services/Civil Construction Project/Braddle MRT/2.jpeg";
import B13 from "../Static/Services/Civil Construction Project/Braddle MRT/3.jpeg";
import B14 from "../Static/Services/Civil Construction Project/Braddle MRT/4.jpeg";
import B15 from "../Static/Services/Civil Construction Project/Braddle MRT/5.jpeg";
import B16 from "../Static/Services/Civil Construction Project/Braddle MRT/6.jpeg";
import B20 from "../Static/Services/Civil Construction Project/Construction of Shelter @ Blk 326D Anchorvale Rd/0.jpg";
import B21 from "../Static/Services/Civil Construction Project/Construction of Shelter @ Blk 326D Anchorvale Rd/1.png";
import B22 from "../Static/Services/Civil Construction Project/Construction of Shelter @ Blk 326D Anchorvale Rd/2.png";
import B23 from "../Static/Services/Civil Construction Project/Construction of Shelter @ Blk 326D Anchorvale Rd/3.png";
import B30 from "../Static/Services/Civil Construction Project/Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra/0.png";
import B31 from "../Static/Services/Civil Construction Project/Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra/1.jpg";
import B32 from "../Static/Services/Civil Construction Project/Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra/2.jpg";
import B33 from "../Static/Services/Civil Construction Project/Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra/3.jpg";
import B34 from "../Static/Services/Civil Construction Project/Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra/4.jpg";
import C10 from "../Static/Services/ID/Health Sciences Authority/0.jpg";
import C11 from "../Static/Services/ID/Health Sciences Authority/1.jpg";
import C12 from "../Static/Services/ID/Health Sciences Authority/2.jpg";
import C13 from "../Static/Services/ID/Health Sciences Authority/3.jpg";
import C14 from "../Static/Services/ID/Health Sciences Authority/4.jpg";
import C15 from "../Static/Services/ID/Health Sciences Authority/5.jpg";
import C16 from "../Static/Services/ID/Health Sciences Authority/6.png";
import C20 from "../Static/Services/ID/Ministry of National Development_Headquarter/0.png";
import C21 from "../Static/Services/ID/Ministry of National Development_Headquarter/1.jpg";
import C22 from "../Static/Services/ID/Ministry of National Development_Headquarter/2.jpg";
import C23 from "../Static/Services/ID/Ministry of National Development_Headquarter/3.jpg";
import C24 from "../Static/Services/ID/Ministry of National Development_Headquarter/4.jpg";
import C25 from "../Static/Services/ID/Ministry of National Development_Headquarter/5.jpg";
import C26 from "../Static/Services/ID/Ministry of National Development_Headquarter/6.jpg";
import C30 from "../Static/Services/ID/NUS Project/0.jpg";
import C31 from "../Static/Services/ID/NUS Project/1.png";
import C32 from "../Static/Services/ID/NUS Project/2.png";
import C33 from "../Static/Services/ID/NUS Project/3.png";
import C34 from "../Static/Services/ID/NUS Project/4.png";
import C35 from "../Static/Services/ID/NUS Project/5.png";
import C36 from "../Static/Services/ID/NUS Project/6.png";
import C37 from "../Static/Services/ID/NUS Project/7.png";
import D10 from "../Static/Services/M&E/Canberra Drive(HDB)/0.jpg";
import D11 from "../Static/Services/M&E/Canberra Drive(HDB)/1.png";
import D12 from "../Static/Services/M&E/Canberra Drive(HDB)/2.png";
import D13 from "../Static/Services/M&E/Canberra Drive(HDB)/3.png";
import D14 from "../Static/Services/M&E/Canberra Drive(HDB)/4.png";
import D15 from "../Static/Services/M&E/Canberra Drive(HDB)/5.png";
import D16 from "../Static/Services/M&E/Canberra Drive(HDB)/6.png";
import D20 from "../Static/Services/M&E/Electrical Submain Work/0.jpg";
import D21 from "../Static/Services/M&E/Electrical Submain Work/1.png";
import D22 from "../Static/Services/M&E/Electrical Submain Work/2.png";
import D23 from "../Static/Services/M&E/Electrical Submain Work/3.png";
import D24 from "../Static/Services/M&E/Electrical Submain Work/4.jpg";
import D25 from "../Static/Services/M&E/Electrical Submain Work/5.jpg";
import D26 from "../Static/Services/M&E/Electrical Submain Work/6.png";
import D27 from "../Static/Services/M&E/Electrical Submain Work/7.png";
import D28 from "../Static/Services/M&E/Electrical Submain Work/8.png";
import D30 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/0.jpg";
import D31 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/1.jpeg";
import D32 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/2.jpeg";
import D33 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/3.jpeg";
import D34 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/4.jpeg";
import D35 from "../Static/Services/M&E/Solar PV System Work/40A Changi Business Park Central 2/5.jpeg";
import D40 from "../Static/Services/M&E/Tampines St 64(HDB)/0.png";
import D41 from "../Static/Services/M&E/Tampines St 64(HDB)/1.png";
import D42 from "../Static/Services/M&E/Tampines St 64(HDB)/2.jpg";
import D43 from "../Static/Services/M&E/Tampines St 64(HDB)/3.png";
import D44 from "../Static/Services/M&E/Tampines St 64(HDB)/4.png";
import D45 from "../Static/Services/M&E/Tampines St 64(HDB)/5.png";
import D46 from "../Static/Services/M&E/Tampines St 64(HDB)/6.png";
import D47 from "../Static/Services/M&E/Tampines St 64(HDB)/7.png";
import D50 from "../Static/Services/M&E/The Singapore island country club/0.jpg";
import D51 from "../Static/Services/M&E/The Singapore island country club/1.jpg";
import D52 from "../Static/Services/M&E/The Singapore island country club/2.jpg";
import D53 from "../Static/Services/M&E/The Singapore island country club/3.jpg";
import E10 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/0.png";
import E11 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/1.png";
import E12 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/2.png";
import E13 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/3.png";
import E14 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/4.png";
import E15 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/5.png";
import E16 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/6.png";
import E17 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/7.png";
import E18 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/8.png";
import E19 from "../Static/Services/R&R/Majlis Ugama Islam Singapura/9.png";
import E20 from "../Static/Services/R&R/STLukes hospital/0.jpg";
import E21 from "../Static/Services/R&R/STLukes hospital/1.jpg";
import E22 from "../Static/Services/R&R/STLukes hospital/2.jpg";
import E23 from "../Static/Services/R&R/STLukes hospital/3.png";
import E24 from "../Static/Services/R&R/STLukes hospital/4.jpg";
import E25 from "../Static/Services/R&R/STLukes hospital/5.jpg";
import E26 from "../Static/Services/R&R/STLukes hospital/6.png";
import F10 from "../Static/Services/Renovation/31 marshlink drive/0.jpg";
import F11 from "../Static/Services/Renovation/31 marshlink drive/1.jpeg";
import F12 from "../Static/Services/Renovation/31 marshlink drive/2.jpeg";
import F13 from "../Static/Services/Renovation/31 marshlink drive/3.png";
import F20 from "../Static/Services/Renovation/ASPN Education Service/0.jpg";
import F21 from "../Static/Services/Renovation/ASPN Education Service/1.jpg";
import F22 from "../Static/Services/Renovation/ASPN Education Service/2.jpg";
import F23 from "../Static/Services/Renovation/ASPN Education Service/3.jpg";
import F24 from "../Static/Services/Renovation/ASPN Education Service/4.jpg";
import "./Project.css";

function Project() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { TabPane } = Tabs;
  const tabNames = [
    "Addition & Alteration",
    "Building and Civil Construction",
    "Interior Decoration Design",
    "Repaire & Redecoration",
    "M&E",
    "Renovations",
  ];

  const imageGroups = [
    [
      [A10, A11, A12, A13, A14, A15, A16],
      [A20, A21, A22, A23, A24, A25, A26, A27, A28],
      [A30, A31, A32, A33, A34, A35],
    ],
    [
      [B10, B11, B12, B13, B14, B15, B16],
      [B20, B21, B22, B23],
      [B30, B31, B32, B33, B34],
    ],
    [
      [C10, C11, C12, C13, C14, C15, C16],
      [C20, C21, C22, C23, C24, C25, C26],
      [C30, C31, C32, C33, C34, C35, C36, C37],
    ],
    [
      [E10, E11, E12, E13, E14, E15, E16, E17, E18, E19],
      [E20, E21, E22, E23, E24, E25, E26],
    ],
    [
      [D10, D11, D12, D13, D14, D15, D16],
      [D20, D21, D22, D23, D24, D25, D26, D27, D28],
      [D30, D31, D32, D33, D34, D35],
      [D40, D41, D42, D43, D44, D45, D46, D47],
      [D50, D51, D52, D53],
    ],
    [
      [F10, F11, F12, F13],
      [F20, F21, F22, F23, F24],
    ],
  ];

  const discription = [
    [
      "Tangling Park @5A Ridley Park",
      "Anglo-Chinies School",
      "Singapore Management University",
    ],
    [
      "Braddle MRT",
      "Construction of Shelter @ Blk 326D Anchorvale Rd",
      "Reconstruction of Terrace Dwelling House @ 25 Jalan Mesra",
    ],
    [
      "Health Sciences Authority",
      "Ministry of National Development_Headquarter",
      "National University of Singapore",
    ],
    ["Repairing Works @ 7 Kelantan Ln", "ST.Lukes Hospital"],
    [
      "HDB Project @Canberra Drive Sembawang(1467 Units)",
      "Electrical Submain Works @ Connection One",
      "Solar Land 2@ 40A Changi Business Park Central 1",
      "HDB Project @Tampines St 64(649 Units)",
      "Singapore Island Country Club",
    ],
    ["Interior Renovation @31 marshlink drive", "ASPN Delta Senior School"],
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: tabNames[0],
      children: (
        <>
          {imageGroups[0].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[0][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
    {
      key: "2",
      label: tabNames[1],
      children: (
        <>
          {imageGroups[1].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[1][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
    {
      key: "3",
      label: tabNames[2],
      children: (
        <>
          {imageGroups[2].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[2][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
    {
      key: "4",
      label: tabNames[3],
      children: (
        <>
          {imageGroups[3].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[3][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
    {
      key: "5",
      label: tabNames[4],
      children: (
        <>
          {imageGroups[4].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[4][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
    {
      key: "6",
      label: tabNames[5],
      children: (
        <>
          {imageGroups[5].map((url, index) => (
            <div>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 0px",
                }}
              >
                {discription[5][index]}
              </span>
              <Image.PreviewGroup items={url}>
                <Image src={url[0]} />
              </Image.PreviewGroup>
              <Divider />
            </div>
          ))}
        </>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(0, 144,207)",
          padding: isMobile ? "2px" : "5px",
          textAlign: "center",
          margin: "20px",
          color: "white",
        }}
      >
        <span
          style={{ fontSize: isMobile ? "24px" : "48px", fontWeight: "bold" }}
        >
          PROJECTS GALLERY
        </span>
      </div>
      <div style={{ display: isMobile ? "none" : "center" }}>
        <Row>
          <Col span={2}></Col>
          <Col span={20}>
            <Tabs
              defaultActiveKey="1"
              centered
              size="large"
              style={{ fontWeight: "bold" }}
            >
              {tabNames.map((name, i) => (
                <TabPane tab={name} key={i + 1}>
                  <Row gutter={16}>
                    {imageGroups[i].map((url, index) => (
                      <Col span={8} key={index}>
                        <Card
                          style={{
                            margin: "10px",
                          }}
                        >
                          <Image.PreviewGroup
                            items={url}
                            style={{ width: "100%" }}
                          >
                            <Image src={url[0]} />
                          </Image.PreviewGroup>
                          <div
                            style={{
                              textAlign: "center",
                              margin: "10px",
                            }}
                          >
                            <div
                              style={{
                                wordWrap: "break-word",
                              }}
                            >
                              <span>{discription[i][index]}</span>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Col span={2}></Col>
        </Row>
      </div>

      <div style={{ display: isMobile ? "" : "none" }}>
        <Collapse items={items} onChange={onChange} />;
      </div>
    </div>
  );
}

export default Project;
