import React, { useState, useEffect } from "react";
import cert1 from "../Static/cert1.jpeg";
import cert2 from "../Static/cert2.png";
import cert3 from "../Static/cert3.jpeg";
import cert4 from "../Static/cert4.jpeg";
import cert5 from "../Static/cert5.jpeg";
import bg2 from "../Static/bg2.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Divider, Button, Row, Col } from "antd";

import a1 from "../Static/client/Left side direct clients/Anglo-Chinese_School.png";
import a2 from "../Static/client/Left side direct clients/APSN-Logo.png";
import a3 from "../Static/client/Left side direct clients/HDB.png";
import a4 from "../Static/client/Left side direct clients/HSA.png";
import a5 from "../Static/client/Left side direct clients/jewel changi.png";
import a6 from "../Static/client/Left side direct clients/logo.png";
import a7 from "../Static/client/Left side direct clients/MND.png";
import a8 from "../Static/client/Left side direct clients/moe-logo.jpg";
import a9 from "../Static/client/Left side direct clients/MUIS.jpg";
import a10 from "../Static/client/Left side direct clients/nus-logo.png";
import a11 from "../Static/client/Left side direct clients/ppl.png";
import a12 from "../Static/client/Left side direct clients/salvation.jpeg";
import a13 from "../Static/client/Left side direct clients/SICClogo-01.png";
import a14 from "../Static/client/Left side direct clients/SLH_logo-_4_.png";
import a15 from "../Static/client/Left side direct clients/smu-logo.jpg";
import a16 from "../Static/client/Left side direct clients/blank.png";

import b1 from "../Static/client/Right side contractors/Elik.png";
import b2 from "../Static/client/Right side contractors/KIENTA.png";
import b3 from "../Static/client/Right side contractors/Lum-Chang-Logo.png";
import b4 from "../Static/client/Right side contractors/TAKENAKA.png";
import b5 from "../Static/client/Right side contractors/TIONG-SENG.png";
import b6 from "../Static/client/Right side contractors/UEL-Logo-intranet.png";
import b7 from "../Static/client/Right side contractors/woh hub.jpeg";

const backgroundStyle = {
  textAlign: "center",
  position: "relative",
};

const overlayStyle = {
  position: "relative",
  width: "50%",
  padding: "20px",
  color: "white",
  textAlign: "left",
  margin: "0 auto",
  backgroundColor: "rgba(27, 27, 27,0.6)",
};

const MobileOverlayStyle = {
  position: "relative",
  width: "90%",
  padding: "20px",
  color: "white",
  textAlign: "left",
  margin: "0 auto",
  backgroundColor: "rgba(27, 27, 27,0.6)",
};

const certStyle = {
  width: "90%",
};

const mobileCertStyle = {
  width: "100%",
};

const settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};

const imageContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px",
};

const mobileImageContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "30px 0px",
};

const logoStyle = {
  width: "60%",
  margin: "0 10px",
};

const mobileLogoStyle = {
  width: "90%",
  margin: "0 5px",
};

function About() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clientDisplay = (a, b, c, d, e) => {
    return (
      <Row style={isMobile ? mobileImageContainerStyle : imageContainerStyle}>
        <Col span={2}></Col>
        <Col span={4}>
          <img
            src={a}
            alt="a1"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={4}>
          <img
            src={b}
            alt="a2"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={4}>
          <img
            src={c}
            alt="a3"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={4}>
          <img
            src={d}
            alt="a3"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={4}>
          <img
            src={e}
            alt="a3"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={2}></Col>
      </Row>
    );
  };

  const contractorsDisplay = (a, b, c, d) => {
    return (
      <Row style={isMobile ? mobileImageContainerStyle : imageContainerStyle}>
        <Col span={2}></Col>
        <Col span={5}>
          <img
            src={a}
            alt="a1"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={5}>
          <img
            src={b}
            alt="a2"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={5}>
          <img
            src={c}
            alt="a3"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={5}>
          <img
            src={d}
            alt="a3"
            style={isMobile ? mobileLogoStyle : logoStyle}
          />
        </Col>
        <Col span={2}></Col>
      </Row>
    );
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <div>
          <img
            src={bg2}
            alt="bg2"
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -1,
              height: "100%",
            }}
          />
        </div>
        <div style={isMobile ? MobileOverlayStyle : overlayStyle}>
          <div
            style={{
              fontSize: isMobile ? "20px" : "64px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Our Company
          </div>
          <div
            style={{
              fontSize: isMobile ? "10px" : "18px",
              lineHeight: 2,
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            NCE CORPORATION (S) PTE. LTD. is a well-established construction
            company in Singapore since 2005, specialized in{" "}
            <span style={{ fontWeight: "bold" }}>
              Building Construction & Civil Works, Mechanical & Electrical (M&E)
              Works, Addition & Alteration (A&A) works, Repair and Redecoration
              (R&R) Works, Interior Decoration and Renovation Works
            </span>
            , with the effort and commitment of multi-skilled professionals. We
            offer variety of services such as consultation, construction,
            demolition, installation, maintenance and furnishing works with
            major focus on commercial and residential buildings. Our services
            include general building work, minor construction work, demolition
            work, interior decoration and finishing works, Solar PV System
            Integration, Electrical Installation & Maintenance Works, Fire
            Prevention & Protection Systems, Housekeeping, Cleansing, Desilting
            & Conservancy Service, registered at CRS-BCA.
            <Divider />
            NCE Corporation (S) Pte Ltd is a company that is built on a
            commitment to delivering premium services in Singapore to
            residential and commercial clients always. Not only are we
            professional in all our dealings, we also excel at leaving our
            clients with confidence and peace of mind with the finished project.
            When it comes to do the renovation, minor construction, installing
            new electrical systems, repair works, call us today and you’ll be
            glad you did.
          </div>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" href="/about">
              More Details
            </Button>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "30px", fontWeight: "bold" }}>
        <div style={{ fontSize: isMobile ? "20px" : "48px" }}>
          Our Certifications
        </div>
        <div style={{ padding: isMobile ? "20px 0px" : "30px" }}>
          <Slider {...settings}>
            <div>
              <img
                src={cert1}
                alt="cert1"
                style={isMobile ? mobileCertStyle : certStyle}
              />
            </div>
            <div>
              <img
                src={cert2}
                alt="cert2"
                style={isMobile ? mobileCertStyle : certStyle}
              />
            </div>
            <div>
              <img
                src={cert3}
                alt="cert3"
                style={isMobile ? mobileCertStyle : certStyle}
              />
            </div>
            <div>
              <img
                src={cert4}
                alt="cert4"
                style={isMobile ? mobileCertStyle : certStyle}
              />
            </div>
            <div>
              <img
                src={cert5}
                alt="cert5"
                style={isMobile ? mobileCertStyle : certStyle}
              />
            </div>
          </Slider>
        </div>
        <Divider
          style={{
            borderColor: "rgba(27, 27, 27,0.5)",
          }}
        />
        <div
          style={{ fontSize: isMobile ? "20px" : "48px", fontWeight: "bold" }}
        >
          Our Clients
        </div>
        <div>
          {isMobile ? <div></div> : <Divider />}
          <Row>
            <Col xs={0} md={2}></Col>
            <Col xs={24} md={20}>
              {clientDisplay(a1, a10, a15, a2, a14)}
              {clientDisplay(a12, a13, a9, a11, a5)}
              {clientDisplay(a3, a7, a8, a4, a6)}
            </Col>
            <Col xs={0} md={2}></Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <div
                style={{
                  backgroundColor: "rgb(0, 144,207)",
                  padding: isMobile ? "1px" : "2px",
                  textAlign: "center",
                  margin: "20px",
                  color: "white",
                }}
              ></div>
            </Col>
            <Col span={4}></Col>
          </Row>

          <Row>
            <Col xs={0} md={4}></Col>

            <Col xs={24} md={16}>
              {contractorsDisplay(b1, b6, b2, b4)}
              {contractorsDisplay(b3, b5, b7, a16)}
            </Col>
            <Col xs={0} md={4}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default About;
