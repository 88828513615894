import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import video from "../Static/showVideo.mp4";

const contentStyle = {
  height: "auto",
  color: "#fff",
  textAlign: "center",
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "30%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: "20px",
};

const lineStyle = {
  width: "1cm",
  height: "6px",
  backgroundColor: "rgb(0,144,207)",
  margin: "10px 0 10px 0",
};

const labelStyle = {
  fontSize: "18px",
  lineHeight: 2.5,
  fontWeight: "bold",
  color: "white",
};

const mobileLineStyle = {
  display: "none",
};

const mobileLabelStyle = {
  display: "none",
};

const titleStyle = {
  fontSize: "12px",
};

const CarouselPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Carousel dots={false}>
      <div style={contentStyle}>
        <video
          autoPlay
          loop
          src={video}
          muted
          preload="auto"
          style={{ width: "100%", height: "100%" }}
        />
        <div style={overlayStyle}>
          <Carousel autoplaySpeed={3000} autoplay dots={false}>
            <div>
              <div
                style={{
                  ...titleStyle,
                  fontSize: isMobile ? "24px" : "48px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                We Are M&E Contractor
              </div>
              <div style={isMobile ? mobileLineStyle : lineStyle} />
              <div style={isMobile ? mobileLabelStyle : labelStyle}>
                We provide reliable, professional licensed electrical,
                fire-alarm, air-conditioning, refrigeration & ventilation,
                mechanical engineering, and integrated building services in
                Singapore. We will resolve your electrical issues with honest
                pricing and within the shortest time possible.
              </div>
            </div>
            <div>
              <div
                style={{
                  ...titleStyle,
                  fontSize: isMobile ? "24px" : "48px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                We Are Builders
              </div>
              <div style={isMobile ? mobileLineStyle : lineStyle} />
              <div style={isMobile ? mobileLabelStyle : labelStyle}>
                As Builders, we give the venture building model our own twist,
                all about minimizing risk and maximizing human potential.
              </div>
            </div>
            <div>
              <div
                style={{
                  ...titleStyle,
                  fontSize: isMobile ? "24px" : "48px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                We Are Reconstructors
              </div>
              <div style={isMobile ? mobileLineStyle : lineStyle} />
              <div style={isMobile ? mobileLabelStyle : labelStyle}>
                If you’re planning on investing in renovation or refurbish of
                your home, we are the good choice Because we provide you with
                some concepts, relatively than setting off with lower cost.
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselPage;
