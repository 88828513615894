import React from "react";
import { Col, Row } from "antd";
import "./Header.css";
import logo from "../Static/logo.png";

const linkStyle = {
  text_decoration: "none",
  color: "black",
};

function Header() {
  return (
    <div>
      <header>
        <Row
          style={{
            textAlign: "center",
            paddingTop: "10px",
            alignItems: "flex-end",
          }}
        >
          <Col xs={0} md={1}></Col>
          <Col xs={0} md={3}>
            <img src={logo} alt="logo" style={{ maxWidth: "100%" }} />
          </Col>
          <Col xs={2} md={0}></Col>
          <Col xs={4} md={2} className="headerItem">
            <a style={linkStyle} href="/">
              <h3>HOME</h3>
            </a>
          </Col>
          <Col xs={4} md={2} className="headerItem">
            <a style={linkStyle} href="/about">
              <h3>ABOUT</h3>
            </a>
          </Col>
          <Col xs={4} md={2} className="headerItem">
            <a style={linkStyle} href="/services">
              <h3>SERVICES</h3>
            </a>
          </Col>
          <Col xs={4} md={2} className="headerItem">
            <a style={linkStyle} href="/project">
              <h3>PROJECT</h3>
            </a>
          </Col>
          <Col xs={4} md={2} className="headerItem">
            <a style={linkStyle} href="/contact">
              <h3>CONTACT</h3>
            </a>
          </Col>
          <Col xs={2} md={0}></Col>
          <Col xs={0} md={6}></Col>
          <Col
            xs={0}
            md={4}
            style={{ textAlign: "left", paddingLeft: "20px", lineHeight: 1.5 }}
          >
            <h5>
              Call: +65 6291 0127 <br />
              Email: admin@ncecorporation.sg
            </h5>
          </Col>
        </Row>
      </header>
    </div>
  );
}

export default Header;
