import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import constructionPic from "../Static/constructionService.jpeg";
import aaPic from "../Static/A&A.png";
import mePic from "../Static/M&E.png";
import "./business.css";

const lineStyle = {
  width: "1cm",
  height: "6px",
  backgroundColor: "rgb(0,144,207)",
  margin: "10px 0 10px 0",
};

const titleStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  color: "rgb(0,144,207)",
};

const MobileTitleStyle = {
  fontSize: "11px",
  fontWeight: "bold",
  color: "rgb(0,144,207)",
};

const contentStyle = {
  fontSize: "14px",
  lineHeight: 2,
};

const MobileContentStyle = {
  fontSize: "10px",
  lineHeight: 2,
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
};

const imageStyle = {
  width: "80%",
  height: "80%",
};

const imageOutStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Business = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Row>
        <Col span={2}></Col>
        <Col span={10} style={imageOutStyle}>
          <img
            src={constructionPic}
            style={imageStyle}
            className="img-hover"
            alt="construction"
          />
        </Col>
        <Col span={10} className="content">
          <div style={cardStyle}>
            <div style={isMobile ? MobileTitleStyle : titleStyle}>
              CONSTRUCTION SERVICES
            </div>
            <div style={lineStyle} />
            <div style={isMobile ? MobileContentStyle : contentStyle}>
              NCE CORPORATION (S) PTE. LTD. offers top-tier construction and
              civil works services in Singapore since 2005. From site
              preparation to finishing touches, we ensure quality, safety, and
              sustainability in every project.
            </div>
          </div>
        </Col>
        <Col span={2}></Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={10} className="content">
          <div style={cardStyle}>
            <div style={isMobile ? MobileTitleStyle : titleStyle}>
              A&A AND RENOVATION SERVICES
            </div>
            <div style={lineStyle} />
            <div style={isMobile ? MobileContentStyle : contentStyle}>
              Transform your space with our A&A and Renovation services. From
              expanding spaces to upgrading interiors, we deliver seamless
              execution and exceptional results tailored to your needs.
            </div>
          </div>
        </Col>
        <Col span={10} style={imageOutStyle}>
          <img
            src={aaPic}
            style={imageStyle}
            className="img-hover"
            alt="construction"
          />
        </Col>

        <Col span={2}></Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={10} style={imageOutStyle}>
          <img
            src={mePic}
            style={imageStyle}
            className="img-hover"
            alt="construction"
          />
        </Col>
        <Col span={10} className="content">
          <div style={cardStyle}>
            <div style={isMobile ? MobileTitleStyle : titleStyle}>
              (M&E)MECHANICAL & ELECTRICAL SERVICES
            </div>
            <div style={lineStyle} />
            <div style={isMobile ? MobileContentStyle : contentStyle}>
              Our Mechanical & Electrical (M&E) services cover all aspects of
              system design, installation, and maintenance for commercial and
              residential buildings, ensuring functionality, efficiency, and
              safety.
            </div>
          </div>
        </Col>
        <Col span={2}></Col>
      </Row>
    </div>
  );
};

export default Business;
