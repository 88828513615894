import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Card } from "antd";
import aboutView from "../Static/aboutview.png";
import mission from "../Static/mission.png";
import vision from "../Static/vision.png";
import projectaa from "../Static/project-aa.png";
import projectbccp from "../Static/project-bccp.jpg";
import projectid from "../Static/project-id.jpg";
import projectme from "../Static/project-me.jpg";
import projectrp from "../Static/project-rp.jpg";
import projectrr from "../Static/project-rr.png";

const rowStyle = {
  display: "flex",
  alignItems: "stretch",
};

const imageStyle = {
  width: "100%",
  objectFit: "cover",
};

const colStyle = {
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
};

const mobileColStyle = {
  display: "none",
};

function AboutCompany() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(0, 144,207)",
          padding: isMobile ? "2px" : "5px",
          textAlign: "center",
          margin: "20px",
          color: "white",
        }}
      >
        <span
          style={{ fontSize: isMobile ? "24px" : "48px", fontWeight: "bold" }}
        >
          ABOUT
        </span>
      </div>
      <Row style={rowStyle}>
        <Col xs={2} md={4}></Col>
        <Col xs={20} md={8}>
          <div
            style={{
              fontSize: isMobile ? "12px" : "1.2rem",
              lineHeight: 1.5,
            }}
          >
            <span
              style={{
                fontSize: isMobile ? "24px" : "48px",
                fontWeight: "bold",
              }}
            >
              OUR COMPANY
            </span>
            <br />
            NCE CORPORATION (S) PTE LTD, established in 2005 in Singapore, is a
            reputable construction company known for quality, innovation, and
            reliability. With over a decade in the industry, we offer a broad
            spectrum of construction services, including Building Construction,
            Civil Works, M&E Works, A&A Works, R&R Works, Interior Decoration,
            and Renovation Works. <br />
            <br />
            Our team of professionals is dedicated to exceeding client
            expectations, ensuring timely project delivery through a unique,
            flexible project management system.
            <br />
            Committed to innovation and excellence, our goal is to achieve the
            hi ghest quality and service standards, ensuring complete client
            satisfaction.
            <Divider />
            NCE Corporation (S) Pte Ltd is a company that is built on a
            commitment to delivering premium services in Singapore to
            residential and commercial clients always. Not only are we
            professional in all our dealings, we also excel at leaving our
            clients with confidence and peace of mind with the finished project.
            When it comes to do the renovation, minor construction, installing
            new electrical systems, repair works, call us today and you’ll be
            glad you did.
          </div>
        </Col>
        <Col xs={0} md={8} style={isMobile ? mobileColStyle : colStyle}>
          <img src={aboutView} alt="aboutView" style={imageStyle} />
        </Col>
        <Col xs={2} md={4}></Col>
      </Row>
      <Row style={{ margin: "30px 0", padding: "0px 10px" }}>
        <Col xs={0} md={4}></Col>
        <Col xs={12} md={8} style={{ textAlign: "center" }}>
          <div>
            <img
              src={mission}
              alt="mission"
              style={{ width: isMobile ? "30%" : "20%" }}
            />
          </div>
          <div
            style={{
              fontSize: isMobile ? "10px" : "18px",
              lineHeight: 2,
              fontWeight: "bold",
            }}
          >
            "To excel in the construction industry by building strong client
            relationships, maintaining integrity, and fostering internal
            teamwork."
          </div>
        </Col>
        <Col xs={12} md={8} style={{ textAlign: "center" }}>
          <div>
            <img
              src={vision}
              alt="vision"
              style={{ width: isMobile ? "30%" : "20%" }}
            />
          </div>
          <div
            style={{
              fontSize: isMobile ? "10px" : "18px",
              lineHeight: 2,
              fontWeight: "bold",
            }}
          >
            "To become a world - class construction company focused on forming
            long - term client relationships, delivering cost effective services
            without compromising quality through high - quality solutions.”
          </div>
        </Col>
        <Col xs={0} md={4}></Col>
      </Row>
      <Divider></Divider>
      <div style={{ textAlign: "center" }}>
        <span
          style={{ fontSize: isMobile ? "24px" : "48px", fontWeight: "bold" }}
        >
          OUR PROJECTS
        </span>
        <Row
          gutter={16}
          style={{
            padding: isMobile ? "10px 0px" : "30px",
          }}
        >
          <Col xs={0} md={3}></Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    Addition <br />& Alteration <br /> Projects
                  </span>
                ) : (
                  "Addition & Alteration Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectaa}
                alt="projectaa"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    Building Civil <br />
                    Construction <br /> Projects
                  </span>
                ) : (
                  "Building and Civil Construction Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectbccp}
                alt="projectbccp"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    Interior <br />
                    Decoration <br />
                    Design Projects
                  </span>
                ) : (
                  "Interior Decoration Design Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectid}
                alt="projectid"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>
        <Row gutter={16} style={{ padding: isMobile ? "10px 0px" : "30px" }}>
          <Col xs={0} md={3}></Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    Repaire & <br />
                    Redecoration <br />
                    Projects
                  </span>
                ) : (
                  "Repaire & Redecoration Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectrr}
                alt="projectrr"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    M&E <br />
                    <br />
                    Projects
                  </span>
                ) : (
                  "M&E Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectme}
                alt="projectme"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={8} md={6}>
            <Card
              title={
                isMobile ? (
                  <span style={{ fontSize: "9px" }}>
                    Renovations <br />
                    <br />
                    Projects
                  </span>
                ) : (
                  "Renovations Projects"
                )
              }
              bordered={false}
              hoverable={true}
            >
              <img
                src={projectrp}
                alt="projectrp"
                style={{ width: "100%" }}
                className="projectItem"
              />
            </Card>
          </Col>
          <Col xs={0} md={3}></Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutCompany;
