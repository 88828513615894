import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import ACMV from "../Static/Services Cover Image/ACMV works.webp";
import Architectural from "../Static/Services Cover Image/Architectural works.jpg";
import Fire from "../Static/Services Cover Image/Fire Protection & Prevention System works.jpg";
import General from "../Static/Services Cover Image/General Building works.jpg";
import Interior from "../Static/Services Cover Image/Interior Decoration & Finishing works.png";
import ME from "../Static/Services Cover Image/M&E works.jpg";
import Plumbing from "../Static/Services Cover Image/Plumbing & Sanitary works.jpg";
import Solar from "../Static/Services Cover Image/Solar PV System works.jpg";

const lineStyle = {
  width: "1cm",
  height: "6px",
  backgroundColor: "rgb(0,144,207)",
  margin: "10px 0 10px 0",
};

const titleStyle = {
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: "rgb(0,144,207)",
};

const mobileTitleStyle = {
  fontSize: "0.8rem",
  fontWeight: "bold",
  color: "rgb(0,144,207)",
};

const contentStyle = {
  fontSize: "13px",
  lineHeight: 2,
};

const mobileContentStyle = {
  fontSize: "9px",
  lineHeight: 1.5,
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  height: "100%",
};

const imageStyle = {
  width: "80%",
  alignContent: "center",
};

const mobileImageStyle = {
  width: "80%",
  alignContent: "top",
};

const imageOutStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const mobileImageOutStyle = {
  justifyContent: "top",
  alignItems: "top",
};

const contentColStyle = {
  height: "200px",
  overflow: "auto",
};

function Service() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const band = (pic, title, introduction) => {
    return (
      <Row>
        <Col span={8} style={isMobile ? mobileImageOutStyle : imageOutStyle}>
          <img
            src={pic}
            style={isMobile ? mobileImageStyle : imageStyle}
            className="img-hover"
            alt="construction"
            align="middle"
          />
        </Col>
        <Col span={16} className="content" style={contentColStyle}>
          <div style={cardStyle}>
            <div style={isMobile ? mobileTitleStyle : titleStyle}>{title}</div>
            <div style={lineStyle} />
            <div style={isMobile ? mobileContentStyle : contentStyle}>
              {introduction.split("\n").map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(0, 144,207)",
          padding: isMobile ? "2px" : "5px",
          textAlign: "center",
          margin: "20px",
          color: "white",
        }}
      >
        <span
          style={{ fontSize: isMobile ? "24px" : "48px", fontWeight: "bold" }}
        >
          OUR SERVICES
        </span>
      </div>
      <div>
        <Row style={{ margin: "30px 0 30px 0" }} justify="center" align="top">
          <Col span={12}>
            {band(
              General,
              "GENERAL BUILDING WORKS",
              "- DESIGN & BUILD \n - RC STRUCTURAL\n - STEEL STRUCTURE WORK\n - CIVIL & ROAD\n- COVERED LINKWAY \n- REINSTATEMENT"
            )}
          </Col>
          <Col span={12}>
            {band(
              ME,
              "MECHANICAL & ELECTRICAL WORKS",
              "- EXPERTS IN COMMERCIAL, RESIDENTIAL, AND INDUSTRIAL M&E WORK.\n- SUPPLY AND INSTALLATION OF FITTINGS & FIXTURES.\n- M&E DRAWING SERVICES WITH PE ENDORSEMENT.\n- PREVENTIVE MAINTENANCE FOR MECHANICAL AND ELECTRICAL SYSTEMS"
            )}
          </Col>
        </Row>
        <Row style={{ margin: "30px 0 30px 0" }} justify="center" align="top">
          <Col span={12}>
            {band(
              ACMV,
              "ACMV WORKS",
              "- EXPERTS IN COMMERCIAL, RESIDENTIAL, AND INDUSTRIAL, TUNNEL ACMV WORK.\n- AIR-CONDITIONING.\n- MECHANICAL VENTILATION SYSTEM.\n- ENGINEERED SMOKE CONTROL SYSTEM.\n- ACMV SYSTEM MAINTENANCE"
            )}
          </Col>
          <Col span={12}>
            {band(
              Plumbing,
              "PLUMBING & SANITARY WORKS",
              "- SPECIALIZE IN INSTALLATION, REPAIRS AND SERVICING OF WATER AND GAS PIPES, SANITARY WORKS AND PLUMBING FIXTURES.\n- PLUMBING & SANITARY, WATER TANK SYSTEM MAINTENANCE.\n- SEWER LINE AND ELECTRO-MECHANICAL BLOCKAGE CLEARING/CLEANING.\n- DRAIN AND GREASE TRAP MAINTENANCE"
            )}
          </Col>
        </Row>
        <Row style={{ margin: "30px 0 30px 0" }} justify="center" align="top">
          <Col span={12}>
            {band(
              Fire,
              "FIRE PREVENTION & PROTECTION SYSTEM WORKS",
              "- AUTOMATIC FIRE SPRINKLER SYSTEM.\n- WET AND DRY RISER SYSTEM.\n- FIRE HYDRANT SYSTEM.\n- HOSEREEL SYSTEM.\n- WET CHEMICAL SUPPRESSION SYSTEM.\n- FIRE PREVENTION & PRODUCTION MAINTENANCE"
            )}
          </Col>
          <Col span={12}>
            {band(
              Solar,
              "SOLAR PV SYSTEM WORKS",
              "- COMMERCIAL PV SYSTEMS.\n- INDUSTRIAL PV SYSTEMS.\n- RESIDENTIAL -METAL ROOF PV SYSTEMS.\n- GROUND MOUNTED PV SYSTEMS"
            )}
          </Col>
        </Row>
        <Row style={{ margin: "30px 0 30px 0" }} justify="center" align="top">
          <Col span={12}>
            {band(
              Architectural,
              "ARCHITECTURAL WORKS",
              "- WET TRADE WORKS.\n- FAÇADE & GLAZING WORKS.\n- DOOR & WINDOW WORKS.\n- PARTITION & DRYWALL WORKS.\n- FALSE CEILING WORKS"
            )}
          </Col>
          <Col span={12}>
            {band(
              Interior,
              "INTERIOR DECORATION & FINISHING WORKS",
              "- INTERIOR DESIGN.\n- AS-BUILT DRAWING.\n- FURNITURE & FITTINGS.\n- CUSTOM FURNITURE.\n- CAEPENTARY WORKS.\n- REFURBISHMENT"
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Service;
